<template>
  <vx-card title="รายการไม่สำเร็จ">
    <vs-table :data="users">
      <template slot="thead">
        <vs-th>
          NO
        </vs-th>
        <vs-th>
          Name
        </vs-th>
        <vs-th>
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>

          <vs-td :data="data[indextr].name">
            {{data[indextr].name}}
          </vs-td>

          <vs-td :data="data[indextr].name">
            <router-link :to="`/Bank/banklisterror/${data[indextr].name}`">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2"
                  >รายการเดินบัญชี</vs-button>
              </router-link>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
export default {
  data () {
    return {
      users: [
        {
          'id': 1,
          'name': 'SCBALL'
        },
        {
          'id': 2,
          'name': 'SCBONLY'
        },
        {
          'id': 3,
          'name': 'KBANK'
        },
        {
          'id': 3,
          'name': 'KBANK_ONLY'
        },
        {
          'id':4,
          'name': 'BAY'
        }
      ]
    }
  }
}

</script>

<style>

</style>